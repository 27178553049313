<template>
  <div class="form-block">
    <fm-form :key="key" ref="form" :inline="inline" :label-align="labelAlign" label-alone>
      <fm-form-item v-for="item in formParms.filter(v => !v.show || v.show(formData))" :key="item.key" :label="item.label" :style="{
        width: item.width,
        height: item.height
      }">
        <template v-slot:labelInfo>
          <span class="label-p">
            <span class="label-p-i" v-if="sourceData && !checkValueWitchSource(item)" @mouseover="openSourceMap[item.key] = true" @mouseleave="openSourceMap[item.key] = false">
              <img style="height: 14px;" src="/static/images/icon/edit.png" alt="">
              <fm-poper v-model="openSourceMap[item.key]" position="right-end">
                <div class="poper-info">
                  <template v-if="!item.formatCmp">
                    <p>该字段当前输入了与原值不同的结果。</p>
                    <p>{{(item.format ? item.format(sourceData) : sourceData[item.key]) ? ('原值: ' + (item.format ? item.format(sourceData) : sourceData[item.key])) : '原值为空'}}</p>
                  </template>
                  <template v-else>
                    <p>该字段当前输入了与原值不同的结果, 原值:</p>
                    <component
                      :is="item.formatCmp"
                      :value="sourceData[item.key]">
                    </component>
                  </template>
                </div>
              </fm-poper>
            </span>
            <span class="label-p-2" :class="{'label-p-2-no1': !(sourceData && !checkValueWitchSource(item))}" v-if="hisWait(item.key)" @mouseover="openWaitMap[item.key] = true" @mouseleave="openWaitMap[item.key] = false">
              <span style="color: #fff;">提</span>
              <fm-poper v-model="openWaitMap[item.key]" position="right-end">
                <div class="poper-info">
                  <template v-if="!item.formatCmp">
                    <p>该字段当前提交了修改申请。</p>
                    <p>{{(item.format ? item.format(waitData) : waitData[item.key]) ? ('申请值为: ' + (item.format ? item.format(waitData) : waitData[item.key])) : '申请值为空'}}</p>
                  </template>
                  <template v-else>
                    <p>该字段当前提交了修改申请, 申请值为:</p>
                    <component
                      :is="item.formatCmp"
                      :value="waitData[item.key]">
                    </component>
                  </template>
                </div>
              </fm-poper>
            </span>
          </span>
        </template>
        <!-- input -->
        <template v-if="['input', 'textarea', 'password'].includes(item.type)">
          <fm-input-new
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            style="width: 100%;"
            :type="item.type"
            @change="onValueChange(item.key)"
            :key="item.key" v-model="formData[item.key]"
            :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            :placeholder="item.placeholder"
          ></fm-input-new>
        </template>
        <!-- select -->
        <template v-if="item.type === 'select'">
          <fm-select
            :class="{'verifier-error': formData[item.key] && !(item.selectDatas || item.getSelectDatas(formData)).find(v => v.key === formData[item.key])}"
            :absolute="isAbsolut"
            @change="onValueChange(item.key)" v-model="formData[item.key]" block :placeholder="item.placeholder || '请选择'" :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))" filterable
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
          >
            <fm-option
              v-for="itemO in (item.selectDatas || item.getSelectDatas(formData))"
              :key="itemO.key"
              :label="itemO.label" :value="itemO.key">
            </fm-option>
          </fm-select>
        </template>
        <!-- multipleChoose -->
        <template v-if="item.type === 'multipleChoose'">
          <fm-select
            :absolute="isAbsolut"
            @change="onValueChange(item.key)" filterable :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))" v-model="formData[item.key]" multiple block
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
          >
            <fm-option v-for="option in item.selectDatas || item.getSelectDatas(formData)" :label="option.label" :value="option.key" :key="option.key"></fm-option>
          </fm-select>
        </template>
        <!-- datePicker -->
        <template v-if="item.type === 'datePicker'">
          <fm-date-picker
            :absolute="isAbsolut"
            style="width: 100%;"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="date"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <!-- datetimePicker -->
        <template v-if="item.type === 'datetimePicker'">
          <fm-date-picker
            :absolute="isAbsolut"
            style="width: 100%;"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            v-verifier
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="datetime"
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <template v-if="item.type === 'monthPicker'">
          <fm-date-picker
            :absolute="isAbsolut"
            :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
            style="width: 100%;"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            @change="onValueChange(item.key)"
            v-model="formData[item.key]"
            :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            type="month"
            :placeholder="item.placeholder">
          </fm-date-picker>
        </template>
        <template v-if="item.type === 'daterange'">
          <fm-date-picker
          :absolute="isAbsolut"
          :clearable="!(item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData)))"
          :placeholder="item.placeholder"
          @change="onValueChange(item.key)"
          :readonly="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
          v-model="formData[item.key]"
          :required="item.check && item.check.required === true"
          :rules="item.check ? (item.check.rules || []) : []"
          v-verifier
          style="width: 100%;"
          type="daterange"></fm-date-picker>
        </template>
        <template v-if="item.type === 'cmp'">
          <component
            :is="item.cmp"
            :required="item.check && item.check.required === true"
            :rules="item.check ? (item.check.rules || []) : []"
            v-verifier
            @change="onValueChange(item.key)"
            :key="item.key"
            v-model="formData[item.key]"
            :disabled="item.fmDisabled || (item.getFmDisabled && item.getFmDisabled(formData))"
            :placeholder="item.placeholder">
          </component>
        </template>
      </fm-form-item>
      <slot name="formItem"></slot>
    </fm-form>
  </div>
</template>

<script>
let dcopy = require('deep-copy')

import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'ModifyApplyForm',
  props: {
    inline: {
      type: [Boolean, Number],
      default: 4,
      validator: function (value) {
        return !(typeof value === 'number' && value < 1 && value > 4)
      }
    },
    sourceData: {
      type: Object,
      default: () => {
        return null
      }
    },
    editData: {
      type: Object,
      default: () => {
        return null
      }
    },
    waitData: {
      type: Object,
      default: () => {
        return null
      }
    },
    waitKeys: {
      type: Array,
      default: () => {
        return []
      }
    },
    labelAlign: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right'].includes(value)
      }
    },
    formParms: {
      type: Array,
      default: () => {
        return []
      },
      validator: (data) => {
        let pass = true
        if (pass) {
          data.forEach((item) => {
            pass = pass && typeof item.key === 'string' && typeof item.type === 'string' && typeof item.label === 'string'
          })
        }
        return pass
      }
    },
    onChange: {
      type: Function,
      default: undefined
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    editData: {
      handler (value) {
        if (value) {
          Object.keys(value).forEach((key) => {
            this.$set(this.formData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
            this.$set(this.hisData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
          })
        } else if (this.sourceData) {
          Object.keys(this.sourceData).forEach((key) => {
            this.$set(this.formData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
            this.$set(this.hisData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
          })
        } else {
          this.formData = {}
          this.hisData = {}
        }
      },
      immediate: true,
      deep: true
    },
    sourceData: {
      handler (value) {
        if (!this.editData && value) {
          Object.keys(this.sourceData).forEach((key) => {
            this.$set(this.formData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
            this.$set(this.hisData, key, Array.isArray(value[key]) ? value[key].map(v => v) : value[key])
          })
        }
      },
      immediate: true,
      deep: true
    },
    formParms: {
      handler (value) {
        value.forEach(v => {
          this.$set(this.openSourceMap, v.key, false)
          this.$set(this.openWaitMap, v.key, false)
        })
      },
      immediate: true
    }
  },
  data () {
    return {
      key: 0,
      formData: {},
      verifierPass: false,
      loading: false,
      openSourceMap: {},
      openWaitMap: {},
      hisData: {}
    }
  },
  methods: {
    hisWait (key) {
      return this.waitKeys.includes(key)
    },
    checkValueWitchSource (formParm) {
      let format = formParm.format
      let compare = formParm.compare
      if (compare) {
        return compare(this.sourceData) === compare(this.formData)
      } else if (format) {
        return format(this.sourceData) === format(this.formData)
      } else {
        return this.sourceData[formParm.key] === this.formData[formParm.key]
      }
    },
    verifier () {
      let noPass = this.formParms.filter(v => !v.show || v.show(this.formData)).find(item => {
        if (item.check) {
          return this.$verifier.check(this.formData[item.key], item.check.required === true, (item.check.rules || [])) !== ''
        }
      })
      this.verifierPass = noPass === undefined
    },
    onValueChange (key) {
      if (typeof this.onChange === 'function') {
        const change = this.onChange(key, this.formData[key], this.formData)
        change && Object.keys(change).forEach(key => {
          this.$set(this.formData, key, change[key])
        })
      }
      this.verifier()
      let isChange = false
      this.formParms.forEach((item) => {
        let key = item.key
        if (!isChange && this.formData[key] !== this.hisData[key]) {
          isChange = true
          if (Array.isArray(this.formData[key]) && Array.isArray(this.hisData[key])) {
            isChange = this.formData[key].join(',') !== this.hisData[key].join(',')
          } else if (this.formData[key] instanceof Date && this.hisData[key]) {
            isChange = this.formData[key].getTime() !== new Date(this.hisData[key]).getTime()
          }
        }
      })
      if (isChange) {
        this.hisData = dcopy(this.formData)
        let data = dcopy(this.formData)
        Object.keys(data).forEach((v) => {
          if (data[v] instanceof Date) {
            data[v] = dateOperating.computeDay({days: 0, date: data[v], format: 'yy-mm-dd hh:mm:ss'})
          }
        })
        this.$emit('formChange', {data: data, pass: this.verifierPass})
      }
    }
  }
}
</script>

<style lang="less">
.form-block {
  position: relative;
  .fm-form-content {
    padding: 0 !important;
  }
}
.poper-info {
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .2);
  background: #fff;
  font-size: 14px;
  padding: 13px;
  border-radius: 0.3rem;
  text-align: left;
}
.label-p {
  position: relative;
  .label-p-i {
    position: absolute;
    top: 0px;
    left: 12px;
  }
  .label-p-2 {
    position: absolute;
    top: 0px;
    left: 38px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    height: 18px;
    width: 18px;
    border-radius: 100%;
  }
  .label-p-2-no1 {
    left: 12px;
  }
}
.pink-theme {
  .poper-info {
    color: #F4628F;
  }
  .label-p {
    .label-p-2 {
      background: #F4628F;
    }
  }
}
.blue-theme {
  .poper-info {
    color: #2F5BEA;
  }
  .label-p {
    .label-p-2 {
      background: #2F5BEA;
    }
  }
}
.yellow-theme {
  .poper-info {
    color: #6C573C;
  }
  .label-p {
    .label-p-2 {
      background: #6C573C;
    }
  }
}
</style>
