<template>
  <div v-if="display">
    <fm-title title-text="身份证"></fm-title>
    <worker-id-card-img
      :worker-data="editData || sourceData"
      :funs="{
        get: true,
        update: true
      }"
      @idcardFileChange="idcardFileChange">
    </worker-id-card-img>
  </div>
</template>

<script>
import {
  dataAppleRequest,
  workerRequest
} from '@/api'

import WorkerIdCardImg from '@/views/worker/worker/workerIdCardImg'

export default {
  props: {
    display: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: Number
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  components: {
    WorkerIdCardImg
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      isChange: false,
      data: {}
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    sourceData () {
      return this.data && this.data.sourceInfo ? this.data.sourceInfo : null
    },
    editData () {
      return this.data && this.data.editInfo ? this.data.editInfo : null
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    completeness () {
      if (!this.formData) {
        return 0
      }
      return ((this.formData.idCardFrontFileId ? 1 : 0) + (this.formData.idCardBackFileId ? 1 : 0)) / 2
    }
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    },
    editData: {
      handler (data) {
        if (data) {
          this.formData = JSON.parse(JSON.stringify(data))
        }
      },
      immediate: true,
      deep: true
    },
    sourceData: {
      handler (data) {
        if (!this.editData && data) {
          this.formData = JSON.parse(JSON.stringify(data))
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async idcardFileChange (parm) {
      let idData = {}
      if (parm.type === 'front') {
        idData.idCardFrontFileId = parm.fileData.id
      }
      if (parm.type === 'back') {
        idData.idCardBackFileId = parm.fileData.id
      }
      let data = Object.assign({}, this.formData, idData)
      this.formChange({
        data,
        pass: true
      })
      await this.save()
    },
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.getEdit({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    testEdited () {
      return this.isChange
    },
    async save () {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      if (saveData && Array.isArray(saveData.positionIds)) {
        saveData.positionIds = saveData.positionIds.join(',')
      }
      await workerRequest.saveEdit(Object.assign({}, this.formData, {
        dataId: this.formData.id,
        id: undefined
      }))
      this.isChange = false
      this.loadData()
      return true
    },
    async submit () {
      let data = await this.save()
      if (data) {
        await dataAppleRequest.submitByWorkerId(this.workerId, {dataType: null})
        this.loadData()
      }
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>
