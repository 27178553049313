<template>
  <modify-apply-block
    :column-list="columnList"
    ref="worker_certificate_other_p"
    :display="display"
    :form-parms-add="formParmsAdd"
    :table-actions="tableActions"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :dataFileParm="{pDirPath: '/worker_certificate/'}"
    block-name="其他证书"
    @dataFileUpdate="dataFileUpdate"
    :init-add-form-data="initAddFormData"
    @completeness="completeness"
    ref-name="worker_certificate_other"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  workerCertificateRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      return await workerCertificateRequest.getEdit({
        type: 'other',
        workerId: this.workerId
      })
    },
    async saveEdit (parm) {
      return await workerCertificateRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await workerCertificateRequest.addEdit(parm)
    },
    async dataFileUpdate (parm, pageVm) {
      await workerCertificateRequest.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'other'
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['worker_certificate_other_p'].save()
    },
    async submit () {
      await this.$refs['worker_certificate_other_p'].submit()
    },
    testEdited () {
      return this.$refs['worker_certificate_other_p'].testEdited()
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '证书名称',
          key: 'mType'
        },
        {
          type: 'input',
          label: '证书编码',
          key: 'code'
        },
        {
          type: 'input',
          label: '证件状态',
          key: 'status'
        }]
        return data
      }
    },
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    columnList () {
      return [{
        title: '证书名称',
        field: 'mType',
        sort: true
      },
      {
        title: '证书编码',
        field: 'code',
        sort: true
      },
      {
        title: '证件状态',
        field: 'status',
        sort: true
      },
      {
        title: '扫描件状态',
        field: 'imgStatusText',
        sort: true
      },
      {
        title: '最近更新扫描件时间',
        field: 'imgUploadTime',
        width: 180,
        sort: true,
        dataType: Date
      }]
    }
  }
}
</script>
