<template>
  <modify-apply-block
    :column-list="columnList"
    ref="train_p"
    :display="display"
    :tableActions="tableActions"
    :dataFileParm="{pDirPath: '/train/'}"
    :form-parms-add="formParmsAdd"
    :onChange="onFormChange"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    block-name="培训与进修信息"
    @completeness="completeness"
    ref-name="train"
    :worker-id="workerId"
    :is-absolut="isAbsolut">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  trainRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  props: {
    isAbsolut: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async getEdit () {
      return await trainRequest.getEdit({
        workerId: this.workerId
      })
    },
    async saveEdit (parm) {
      return await trainRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await trainRequest.addEdit(parm)
    },
    onFormChange (key, value, data) {
      if (key === 'startDate' || key === 'endDate') {
        if (data.startDate && data.endDate) {
          let day = (new Date(data.endDate) - new Date(data.startDate)) / 1000 / 60 / 60 / 24 + 1
          return {
            dayNum: day
          }
        }
      }
    },
    completeness (data) {
      this.$emit('completeness', data)
    },
    async save () {
      await this.$refs['train_p'].save()
    },
    async submit () {
      await this.$refs['train_p'].submit()
    },
    testEdited () {
      return this.$refs['train_p'].testEdited()
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'draft_file',
        label: '扫描件',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '培训主办单位名称',
          key: 'zbdw'
        },
        {
          type: 'select',
          label: '培训类型',
          selectDatas: this.$store.getters.selectDatas['train_type_list'],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '出国（出境）培训标识',
          selectDatas: ['是', '否'].map(v => {return {key: v, label: v}}),
          key: 'cg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '教育培训性质',
          selectDatas: this.$store.getters.selectDatas['train_jypx_list'],
          key: 'jypx',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '培训项目名称',
          key: 'pxxm'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['train_lg_list'],
          label: '培训离岗状态',
          key: 'lg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '从学单位类别',
          selectDatas: this.$store.getters.selectDatas['train_cxdw_list'],
          key: 'cxdw',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训起始日期',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训结束日期',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '培训天数',
          key: 'dayNum',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    columnList () {
      return [{
        title: '培训主办单位名称',
        field: 'zbdw',
        sort: true
      },
      {
        title: '培训类型',
        field: 'type',
        sort: true
      },
      {
        title: '出国（出境）培训标识',
        field: 'cg',
        sort: true
      },
      {
        title: '教育培训性质',
        field: 'jypx',
        sort: true
      },
      {
        title: '培训项目名称',
        field: 'pxxm',
        sort: true
      },
      {
        title: '培训离岗状态',
        field: 'lg',
        sort: true
      },
      {
        title: '从学单位类别',
        field: 'cxdw',
        sort: true
      },
      {
        title: '培训起始日期',
        field: 'startDate',
        sort: true,
        dataType: Date,
        format: (rowData) => {
          return rowData && rowData.startDate ? rowData.startDate.slice(0, 10) : '-'
        }
      },
      {
        title: '培训结束日期',
        field: 'endDate',
        sort: true, dataType: Date,
        format: (rowData) => {
          return rowData && rowData.endDate ? rowData.endDate.slice(0, 10) : '-'
        }
      },
      {
        title: '培训天数',
        field: 'dayNum',
        sort: true, dataType: Number, width: 130
      }]
    }
  }
}
</script>
