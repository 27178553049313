<template>
  <sign-block
    v-if="display"
    :ref="refName"
    :title-text="blockName"
    :request="request"
    :simpleFilter="false"
    :titleMenus="titleMenus || [{key: 'add', label: '新增'}]"
    :column-list="useColumnList"
    :onFormChange="onChange"
    :data-file-parm="dataFileParm"
    :form-parms-add="formParmsAdd"
    :dataFilefuns="dataFilefuns"
    table-size="large"
    :delSuccessInfo="delSuccessInfo"
    :tableActions="tableActions || normTableActions"
    @tableAction="tableAction"
    :form-parms-update="formParmsAdd"
    :initAddFormData="initAddFormData || initAddFormDataNorm"
    :need-data-file="true">
    <slot></slot>
    <fm-modal
      :value="openDialog"
      title="扫描件"
      width="600"
      :mask-closable="false"
      @cancel="openDialog = false">
      <div class="file-type-item">
        <div class="type-name">编辑中</div>
        <file-manage
        v-if="openDialog"
        @uploadIng="uploadIng"
        :file-data-ids="editFileIds"
        @addFile="addFile"
        @delFile="delFile"
        :funs="{get: true, upload: true, del: true}"></file-manage>
      </div>
      <div class="file-type-item">
        <div class="type-name">原数据中</div>
        <file-manage
        v-if="openDialog"
        :file-data-ids="sourceFileIds"
        :funs="{get: true}"></file-manage>
      </div>
      <div class="file-type-item">
        <div class="type-name">已提交</div>
        <file-manage
        v-if="openDialog"
        :file-data-ids="modifyApplyFileIds"
        :funs="{get: true}"></file-manage>
      </div>
        <div style="margin: 40px 0 20px;display: flex;justify-content: center;">
          <fm-btn @click="submitFile" style="margin-right: 20px;">提交</fm-btn>
          <fm-btn @click="openDialog = false">关闭</fm-btn>
        </div>
    </fm-modal>
  </sign-block>
</template>

<script>
import {
  dataAppleRequest
} from '@/api'

import FileManage from '@/components/base/FileManage'

import DataForm from './dataForm'

import ModifyApplyLabel from './modifyApplyLabel'

import {
  dateOperating
} from '@/fmlib'

export default {
  components: {
    FileManage
  },
  props: {
    isAbsolut: {
      type: Boolean,
      default: true
    },
    noStatus: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function
    },
    getEdit: {
      type: Function
    },
    saveEdit: {
      type: Function
    },
    addEdit: {
      type: Function
    },
    columnList: {
      type: Array
    },
    titleMenus: {
      type: Array
    },
    dataFileParm: {
      type: Object
    },
    tableActions: {
      type: Array
    },
    initAddFormData: {
      type: Function
    },
    formParmsAdd: {
      type: Array
    },
    dealDataInfo: {
      type: Function
    },
    blockName: {
      type: String
    },
    refName: {
      type: String
    },
    workerId: {
      type: [String, Number]
    },
    display: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    uploadIng (loading) {
      this.loading = loading
    },
    async addFile (fileData) {
      this.loading = true
      this.editFileIds.push(fileData.id)
      let parm = Object.assign(this.chooseFormDataItem.editInfo || this.chooseFormDataItem.sourceInfo)
      parm.fileIds = this.editFileIds.join(',')
      parm.imgUploadTime = dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      await this.update(this.chooseFormDataItem.sourceInfo.id, parm)
      await this.save()
      this.loading = false
    },
    async delFile (fileData) {
      this.loading = true
      this.editFileIds = this.editFileIds.filter(v => Number(v) != fileData.id)
      let parm = Object.assign(this.chooseFormDataItem.editInfo || this.chooseFormDataItem.sourceInfo)
      parm.fileIds = this.editFileIds.join(',')
      parm.imgUploadTime = dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'})
      await this.update(this.chooseFormDataItem.sourceInfo.id, parm)
      await this.save()
      this.loading = false
    },
    async submitFile () {
      if (this.loading) {
        this.$notice.info({
          title: '系统提示',
          desc: '文件上传中，请稍后。'
        })
        return
      }
      await this.submit()
      this.openDialog = false
    },
    delSuccessInfo (title) {
      return title + '删除完成'
    },
    testEdited () {
      return this.formDataList.find(v => v.edit && v.edit.__change)
    },
    async add (parm) {
      await this.addEdit(parm)
      return await this.save()
      // return await this.load()
    },
    async update (id, parm) {
      let newDataInfo = Object.assign({}, parm)
      if (this.dealDataInfo) {
        newDataInfo = this.dealDataInfo(newDataInfo)
      }
      this.formDataList.forEach((v) => {
        if (v.sourceInfo.id === id) {
          v.edit = Object.assign({}, v.edit, {
            __change: true
          })
          v.editInfo = newDataInfo
        }
      })
      this.$refs[this.refName].loadData()
      return Promise.resolve()
    },
    async updateDelFiles () {
      // let id = this.chooseFormDataItem.draft.id
      // this.formDataList.forEach((v) => {
      //   if (v.draft.id === id) {
      //     v.draft.__change = true
      //     v.draft.delFiles = parm
      //   }
      // })
      // this.$refs[this.refName].loadData()
      return Promise.resolve()
    },
    async del (id) {
      this.formDataList.forEach((v) => {
        if (v.sourceInfo.id === id) {
          v.edit = Object.assign({}, v.edit, {
            __change: true
          })
          v.editInfo = v.editInfo || v.sourceInfo
          v.editInfo.isDel = 1
        }
      })
      return Promise.resolve()
    },
    backDel (id) {
      this.formDataList.forEach((v) => {
        if (v.sourceInfo.id === id) {
          v.edit = Object.assign({}, v.edit, {
            __change: true
          })
          v.editInfo = v.editInfo || v.sourceInfo
          v.editInfo.isDel = 0
        }
      })
      return Promise.resolve()
    },
    async save () {
      let i = 0
      while (i < this.formDataList.length) {
        if (this.formDataList[i].edit && this.formDataList[i].edit.__change) {
          await this.saveEdit(this.formDataList[i].editInfo)
        }
        i += 1
      }
      this.load()
    },
    async submit () {
      await this.save()
      await dataAppleRequest.submitByWorkerId(this.workerId, {dataType: null})
      this.load()
    },
    dealApplyData ({hisData, newData}, data) {
      hisData.workerId = data.workerId
      return {hisData, newData}
    },
    initAddFormDataNorm () {
      return {
        workerId: this.workerId
      }
    },
    // testSame (wait, draft) {
    //   let same = true
    //   if (!wait) {
    //     same = false
    //   } else {
    //     if (wait.type !== draft.type) {
    //       same = false
    //     } else {
    //       let waitData = wait.newData ? JSON.parse(wait.newData) : null
    //       let draftData = draft.newData ? JSON.parse(draft.newData) : null
    //       if (!waitData) {
    //         same = false
    //       } else {
    //         Object.keys(waitData).forEach(key => {
    //           if (waitData[key] !== draftData[key]) {
    //             same = false
    //           }
    //         })
    //       }
    //     }
    //   }
    //   return same
    // },
    async load () {
      this.formDataList = await this.getEdit()
      if (this.$refs[this.refName]) {
        this.$refs[this.refName].loadData()
      }
    },
    async loadData () {
      let data = this.formDataList.map((v) => {
        let dataItem = v.editInfo || v.sourceInfo
        dataItem.dataStatusMap = {}
        if (v.edit && v.edit.id) {
          if (v.editInfo.isDel) {
            dataItem.dataStatusMap.edit = 'del'
          } else if (v.sourceInfo.dataStatusMap === 'edit') {
            dataItem.dataStatusMap.edit = 'add'
          } else {
            dataItem.dataStatusMap.edit = 'edit'
          }
        }
        if (v.modifyApply) {
          dataItem.dataStatusMap.modifyApply = v.modifyApply.type
        }
        return dataItem
      })
      return Promise.resolve(data)
    },
    testDiff (dataItem, key, format, formatValue) {
      let diff = false
      if (dataItem.id) {
        let data = this.formDataList.find(v => v.sourceInfo.id === dataItem.id)
        if (data && data.editInfo) {
          if (formatValue) {
            diff = formatValue(data.editInfo) !== formatValue(data.sourceInfo)
          } else if (format) {
            diff = format(data.editInfo) !== format(data.sourceInfo)
          } else {
            diff = data.editInfo[key] !== data.sourceInfo[key]
          }
        }
      }
      return diff
    },
    tableAction ({action, data, index}) {
      if (action === 'table-expand-edit') {
        this.tableExpand(data, index)
      } else if (action === 'draft_file') {
        this.chooseFormDataItem = this.formDataList.find(v => v.sourceInfo.id === data.id)
        this.editFileIds = this.chooseFormDataItem.editInfo && this.chooseFormDataItem.editInfo.fileIds ? this.chooseFormDataItem.editInfo.fileIds.split(',') : null
        this.modifyApplyFileIds = this.chooseFormDataItem.modifyApplyInfo && this.chooseFormDataItem.modifyApplyInfo.fileIds ? this.chooseFormDataItem.modifyApplyInfo.fileIds.split(',') : []
        this.sourceFileIds = this.chooseFormDataItem.sourceInfo.dataStatus === 'real' && this.chooseFormDataItem.sourceInfo && this.chooseFormDataItem.sourceInfo.fileIds ? this.chooseFormDataItem.sourceInfo.fileIds.split(',') : []
        if (this.editFileIds === null) {
          this.editFileIds = this.sourceFileIds.map(v => v)
        }
        this.openDialog = true
      } else if (action === 'back_del') {
        this.backDel(data.id)
      }
    },
    tableExpand (row, dataIndex) {
      this.$refs[this.refName].$refs.page.$refs.table.expandRow(row, dataIndex, (h, rowData) => {
        let formDataItem = this.formDataList.find(v => v.sourceInfo.id === rowData.id)
        let waitKeys = formDataItem && formDataItem.modifyApply && formDataItem.modifyApply.newData ? Object.keys(JSON.parse(formDataItem.modifyApply.newData)) : []
        return h(DataForm, {
          props: {
            formParms: this.formParmsAdd,
            sourceData: formDataItem.sourceInfo,
            editData: rowData,
            waitData: formDataItem.modifyApplyInfo ? formDataItem.modifyApplyInfo : {},
            waitKeys: waitKeys,
            isAbsolut: this.isAbsolut,
            onChange: this.onChange
          },
          on: {
            formChange: (data) => {
              if (data.pass) {
                this.update(data.data.id, data.data)
              }
            }
          },
          style: {
            'margin': '36px 0 16px'
          }
        })
      })
    }
  },
  created () {
    this.load()
  },
  computed: {
    dataFilefuns () {
      return {
        get: true,
        upload: true,
        down: true,
        del: true,
        mkdir: false
      }
    },
    dirPath () {
      let data = null
      if (this.dataFileParm && this.dataFileParm.pDirPath) {
        data = this.dataFileParm && this.dataFileParm.pDirPath
      }
      return data
    },
    showDataList () {
      return this.formDataList.map((v) => {
        return v.sourceInfo || v.editInfo
      })
    },
    completeness () {
      const keys = this.formParmsAdd.map(v => v.key)
      const num = this.showDataList.reduce((a, item) => {
        return a + keys.reduce((c, key) => {
          if (typeof key === 'string' && ((Array.isArray(item[key]) && item[key].length) || (!Array.isArray(item[key]) && item[key]))) {
            return c + 1
          } else {
            return c
          }
        }, 0)
      }, 0)
      return num / (keys.length * (this.showDataList.length || 1))
    },
    normTableActions () {
      return [{
        key: 'table-expand-edit',
        label: '修改',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'del',
        label: '删除',
        show: (data) => {
          return !data.isDel
        }
      },
      {
        key: 'back_del',
        label: '恢复',
        show: (data) => {
          return data.isDel
        }
      }]
    },
    useColumnList () {
      let vm = this
      let data = [{
        title: '状态',
        field: 'dataStatusMap',
        width: 120,
        search: false,
        export: false,
        configurable: false,
        render: (h, rowData) => {
          return h(ModifyApplyLabel, {
            props: {
              dataStatus: rowData.dataStatusMap
            }
          })
        }
      }]
      let data2 = this.columnList.map(v => {
        return Object.assign({}, v, {
          render: (h, rowData) => {
            return h('div', {
              style: {
                color: vm.testDiff(rowData, v.field, v.format, v.formatValue) ? '#ec9738' : null
              }
            }, (v.format ? v.format(rowData) : rowData[v.field]) || '-')
          }
        })
      })
      if (this.noStatus) {
        data = []
      }
      return data.concat(data2)
    }
  },
  data () {
    return {
      loading: false,
      editFileIds: [],
      modifyApplyFileIds: [],
      sourceFileIds: [],
      chooseFormDataItem: null,
      formDataList: [],
      openDialog: false,
      request: {
        get: this.loadData,
        add: this.add,
        update: this.update,
        del: this.del
      }
    }
  }
}
</script>

<style lang="less">
.file-type-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #999;
  padding: 20px 0;
  .type-name {
    width: 10rem;
  }
}
</style>