<style lang="less">
.fm-modal.all-info {
  .fm-modal-header {
    padding-bottom: 0 !important;
  }
  .fm-modal-body {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>

<template>
  <fm-modal
    :value="openDialog"
    width="95%"
    :mask-closable="false"
    theme="mh-withe"
    @cancel="handleClose">
    <div class="edit-user-form-content" v-loadingx="loading">
      <fm-title title-text="我的个人信息" :titleMenus="chooseWorkerId ? [{key: 'showAll', label: '总览'}] : []" @clickTitleMenu="clickTitleMenu">
        <dir style="display: flex;justify-content: space-between;align-items: center; flex: 1;">
          <div>
          <fm-select
            filterable
            v-model="chooseWorkerId"
            block
            v-show="workerList && workerList.length > 1"
            :clearable="false">
            <fm-option v-for="option in workerList" :label="option.label" :value="option.key" :key="option.key"></fm-option>
          </fm-select>
          </div>
          <progress-bar v-if="!changeIng && chooseWorkerId" :value="progressValue"/>
        </dir>
      </fm-title>
      <div class="c-t" v-if="!changeIng && chooseWorkerId" style="margin-top: 10px;">
        <step @setStep="setStep" :cStep="cStep.key" :steps="steps" :completeness="completeness"></step>
      </div>
      <div class="c-c" v-if="!changeIng && chooseWorkerId">
        <component
          :display="cStep.key === step.key"
          :key="step.key"
          v-for="(step, index) in steps"
          :ref="'efcmp' + index" :is="step.cmp"
          :worker-id="chooseWorkerId"
          :is-absolut="isAbsolut"
          @completeness="v => completenessChange(step.key, v)"></component>
      </div>
      <div class="c-b" v-if="!changeIng && chooseWorkerId">
        <fm-btn style="margin: 0 20px;" @click="save" v-if="cStep.key !== 'idcard'">保存</fm-btn>
        <fm-btn style="margin: 0 20px;" @click="submit" v-if="cStep.key !== 'idcard'">提交</fm-btn>
        <fm-btn style="margin: 0 20px;" @click="next(-1)" v-if="cStepIndex > 0">上一步</fm-btn>
        <fm-btn style="margin: 0 20px;" @click="next(1)" v-if="cStepIndex < steps.length - 1">下一步</fm-btn>
        <fm-btn style="margin: 0 20px;" @click="handleClose">关闭</fm-btn>
      </div>
    </div>
    <fm-modal class="all-info" :mask-closable="true" v-model="showAll" width="80%" v-if="showAll">
      <div slot="header" style="text-align: center;margin-bottom: 20px;font-size: 25px;">个人信息总览</div>
      <allInfo :worker-id="chooseWorkerId"></allInfo>
    </fm-modal>
    <data-apply-log v-model="showLog" :show-tool-box="showToolBox" />
  </fm-modal>
</template>

<script>
import WorkerDataBase from './cmp/workerDataBase'
import WorkerDataWork from './cmp/workerDataWork'
import EduData from './cmp/eduData'
import HisWorkData from './cmp/hisWorkData'
import Rew from './cmp/rew'
import Train from './cmp/train'
import CertificateData from './cmp/certificateData'
import CertificateDataDoctorPra from './cmp/certificateDataDoctorPra'
import CertificateDataDoctorQua from './cmp/certificateDataDoctorQua'
import CertificateDataOther from './cmp/certificateDataOther'
import CertificateDataProHire from './cmp/certificateDataProHire'
import JobTitleData from './cmp/jobTitleData'
import WorkerIdCard from './cmp/workerIdCard'

import AllInfo from '../info/allInfo'

import Step from './step'
import ProgressBar from './progress.vue'

import DataApplyLog from '@/components/base/data-apply/modal'

let steps = Object.freeze([{
  key: 'base',
  label: '基础信息',
  cmp: WorkerDataBase,
},
{
  key: 'work',
  label: '工作信息',
  cmp: WorkerDataWork
},
{
  key: 'work_id_card',
  label: '身份证',
  cmp: WorkerIdCard
},
{
  key: 'edu_data',
  label: '教育经历',
  cmp: EduData
},
{
  key: 'his_work',
  label: '工作经历',
  cmp: HisWorkData
},
{
  key: 'rew',
  label: '奖励信息',
  cmp: Rew
},
{
  key: 'train',
  label: '培训与进修信息',
  cmp: Train
},
{
  key: 'cf',
  label: '专业技术资格证书',
  cmp: CertificateData
},
{
  key: 'doctor_pra',
  label: '执业证书',
  cmp: CertificateDataDoctorPra
},
{
  key: 'doctor_que',
  label: '职业资格证书',
  cmp: CertificateDataDoctorQua
},
{
  key: 'job_title',
  label: '职称聘任记录',
  cmp: JobTitleData
},
{
  key: 'pro_hire',
  label: '专业技术职务聘任证书',
  cmp: CertificateDataProHire
},
{
  key: 'other',
  label: '其他证书',
  cmp: CertificateDataOther
}])

export default {
  components: {
    Step,
    AllInfo,
    ProgressBar,
    DataApplyLog
  },
  data () {
    return {
      changeIng: false,
      chooseWorkerId: this.workerId,
      cStep: steps[0],
      steps: steps,
      showAll: false,
      showLog: false,
      completeness: {},
      loading: false
    }
  },
  watch: {
    chooseWorkerId () {
      this.changeIng = true
      this.$nextTick(() => {
        this.changeIng = false
      })
      this.cStep = this.steps[0]
      this.completeness = {}
    }
  },
  async created () {
    this.$store.dispatch('loadWorkerList')
  },
  computed: {
    workerList () {
      return this.$store.getters.workerList
    },
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    cStepIndex () {
      return this.steps.indexOf(this.cStep)
    },
    progressValue () {
      let keys = Object.keys(this.completeness)
      let value = keys.reduce((a, b) => a + this.completeness[b], 0)
      value = Number((value / keys.length * 100).toFixed(0))
      return isNaN(value) ? 0 : value
    }
  },
  props: {
    workerId: {
      type: [String, Number]
    },
    openDialog: {
      type: Boolean
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    completenessChange (key, v) {
      this.$set(this.completeness, key, Number(v.toFixed(2)))
    },
    clickTitleMenu (key) {
      if (key === 'showAll') {
        this.showAll = true
      } else if (key === 'log') {
        this.showLog = true
      }
    },
    async save () {
      let vm = this.$refs['efcmp' + this.cStepIndex][0]
      if (typeof vm.save === 'function') {
        this.loading = true
        try {
          await vm.save()
          this.loading = false
        } catch (e) {
          console.log(e)
          this.loading = false
        }
      }
    },
    async submit () {
      let vm = this.$refs['efcmp' + this.cStepIndex][0]
      if (typeof vm.submit === 'function') {
        this.loading = true
        try {
          await vm.submit()
          this.loading = false
        } catch (e) {
          console.log(e)
          this.loading = false
        }
      }
    },
    check () {
      let ok = true
      if (this.$refs['efcmp']) {
        let vm = this.$refs['efcmp' + this.cStepIndex][0]
        if (typeof vm.testEdited === 'function' && vm.testEdited()) {
          this.$notice.info({
            title: '系统提示',
            desc: '请保存后再进入下一步'
          })
          ok = false
        }
      }
      return ok
    },
    next (nIndex) {
      if (this.check()) {
        this.cStep = this.steps[this.cStepIndex + nIndex]
        if (this.$refs['efcmp' + this.cStepIndex][0].loadData) {
          this.$refs['efcmp' + this.cStepIndex][0].loadData()
        }
      }
    },
    setStep (i) {
      if (this.check()) {
        this.cStep = this.steps[i]
        if (this.$refs['efcmp' + this.cStepIndex][0].loadData) {
          this.$refs['efcmp' + this.cStepIndex][0].loadData()
        }
      }
    },
    async handleClose () {
      if (!this.check()) {
        const result = await this.$dialog.confirm({title: '温馨提示', content: '当前数据有未保存的变更，如果强制关闭将会丢失未保存的数据！'})
        if (result) {
          this.$emit('handleClose')
        }
      } else {
        const result = await this.$dialog.confirm({okText: '确认关闭', cancelText: '返回提交', title: '温馨提示', content: '如果您已编辑好需要修改的数据，请在关闭前确保已提交修改申请！'})
        if (result) {
          this.$emit('handleClose')
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.c-b {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px;
}
</style>

<style lang="less">
.edit-user-form-content {
  .content-body {
    padding-left: 16px;
  }
}
</style>
