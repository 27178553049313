<template>
  <div v-if="display">
    <fm-title title-text="基础信息"></fm-title>
    <data-form
      :wait-data="waitData"
      :source-data="sourceData"
      :edit-data="editData"
      :form-parms="formParms"
      @formChange="formChange"
      :onChange="onChange"
      :is-absolut="isAbsolut"
      :wait-keys="waitKeys">
      <template v-slot:formItem>
        <worker-img :workerData="editData || sourceData" @headFileChange="headFileChange"></worker-img>
      </template>
    </data-form>
  </div>
</template>

<script>
import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

import {
  dateOperating
} from '@/fmlib'

import {
  dataAppleRequest,
  workerRequest
} from '@/api'

import DataForm from './dataForm'
import WorkerImg from '@/views/worker/worker/workerImg'

export default {
  props: {
    display: {
      type: Boolean,
      default: true
    },
    workerId: {
      type: Number
    },
    isAbsolut: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DataForm,
    WorkerImg
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      isChange: false,
      data: {}
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    sourceData () {
      let data = this.data && this.data.sourceInfo ? this.data.sourceInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    editData () {
      let data = this.data && this.data.editInfo ? this.data.editInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    formParms () {
      let data = [{
        type: 'input',
        label: '姓名',
        key: 'name'
      },
      {
        type: 'select',
        label: '性别',
        key: 'sex',
        selectDatas: [{key: '男', label: '男'}, {key: '女', label: '女'}]
      },
      {
        type: 'select',
        label: '民族',
        key: 'clan',
        selectDatas: this.$store.getters.selectDatas['worker_clan_list']
      },
      {
        type: 'select',
        label: '政治面貌',
        key: 'politicalStatus',
        selectDatas: this.$store.getters.selectDatas['worker_political_status_list']
      },
      {
        type: 'input',
        label: '身份证号码',
        key: 'idNo'
      },
      {
        type: 'datePicker',
        label: '出生年月',
        key: 'birth',
        format: (data) => {
          return data.birth ? dateOperating.computeDay({days: 0, date: data.birth, format: 'yy-mm-dd'}) : null
        }
      },
      {
        type: 'input',
        label: '年龄',
        fmDisabled: true,
        key: 'age'
      },
      {
        type: 'select',
        label: '个人身份',
        fmDisabled: this.workerApplyEditType1,
        key: 'sf',
        selectDatas: this.$store.getters.selectDatas['worker_identity_list']
      },
      {
        type: 'select',
        label: '学历',
        key: 'education',
        selectDatas: this.$store.getters.selectDatas['worker_education_list']
      },
      {
        type: 'select',
        label: '学位',
        key: 'degree',
        selectDatas: this.$store.getters.selectDatas['worker_edu_degree']
      },
      {
        type: 'input',
        label: '籍贯',
        key: 'hometown'
      },
      {
        type: 'input',
        label: '联系电话',
        key: 'phone'
      },
      {
        type: 'select',
        label: '是否特殊人才',
        key: 'agreementCode',
        fmDisabled: this.workerApplyEditType1,
        selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}]
      },
      {
        type: 'select',
        label: '是否港澳台及外籍人士',
        key: 'graduatedSchool',
        selectDatas: [{key: '是', label: '是'}, {key: '否', label: '否'}]
      }]
      if (!this.workerShowSelfId) {
        data = data.filter(v => v.key !== 'sf')
      }
      let i = 0
      while (i < data.length) {
        if ((i + 1) % 4 === 0) {
          let d1 = data.slice(0, i)
          let d2 = data.slice(i)
          d1.push({
            type: 'null',
            label: '',
            key: String(i)
          })
          data = d1.concat(d2)
        }
        i += 1
      }
      return data
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    workerShowSelfId () {
      return this.$store.getters.sysConfig['workerShowSelfId']
    },
    completeness () {
      if (!this.formData) {
        return 0
      }
      return this.formParms.filter(v => v.type !== 'null' && this.formData[v.key]).length / this.formParms.filter(v => v.type !== 'null').length
    }
  },
  watch: {
    completeness: {
      handler (data) {
        this.$emit('completeness', data)
      },
      immediate: true,
      deep: true
    },
    editData: {
      handler (data) {
        if (data) {
          this.formData = JSON.parse(JSON.stringify(data))
        }
      },
      immediate: true,
      deep: true
    },
    sourceData: {
      handler (data) {
        if (!this.editData && data) {
          this.formData = JSON.parse(JSON.stringify(data))
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.getEdit({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    onChange (key, value) {
      let rData = {}
      if (key === 'idNo') {
        let birth = getBirthByIdNo(value)
        if (birth) {
          rData.birth = birth
          rData.age = getAge(birth)
        }
      }
      if (key === 'birth') {
        rData.age = getAge(value)
      }
      return rData
    },
    testEdited () {
      return this.isChange
    },
    async save () {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      if (saveData && Array.isArray(saveData.positionIds)) {
        saveData.positionIds = saveData.positionIds.join(',')
      }
      await workerRequest.saveEdit(Object.assign({}, this.formData, {
        dataId: this.formData.id,
        id: undefined
      }))
      this.isChange = false
      this.loadData()
      return true
    },
    async submit () {
      let data = await this.save()
      if (data) {
        await dataAppleRequest.submitByWorkerId(this.workerId, {dataType: null})
        this.loadData()
      }
    },
    async headFileChange (fileData) {
      let data = Object.assign({}, this.formData, {
        headFileId: fileData.id
      })
      this.formChange({
        data,
        pass: true
      })
      await this.save()
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>
